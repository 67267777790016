import React from "react"
import Layout from "../../components/layout"
import VideosMenu from "../../components/videosMenu"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const VideosOtros = () => {
  return (
    <Layout>
      <GatsbySeo
        title="Otros - Videos"
        titleTemplate="%s | Eduardo Ceballos"
        description="Videos"
        canonical="https://eduardoceballos.com/videos/otros"
        openGraph={{
          url: `https://eduardoceballos.com/videos/otros`,
          title: `Otros - Videos`,
          description: `Otros - Videos`,
          images: [
            {
              url: `https://eduardoceballos.com/seo/videos.jpg`,
              alt: `Videos`,
            },
          ],
          site_name: "Eduardo Ceballos",
        }}
        twitter={{
          handle: "@PoetadeSalta",
          site: "@PoetadeSalta",
          cardType: "summary_large_image",
        }}
      />
      <h1>Videos - Otros</h1>
      <VideosMenu />
      <div class="videos-grid" id="otros">
        <div className="video-padding">
          <iframe
            className="lazyload"
            title="Vivencias - Eduardo Ceballos (Parte 1)"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/tRyYNYlupBA?feature=oembed"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""
          />
        </div>

        <div className="video-padding">
          <iframe
            className="lazyload"
            title="Vivencias - Eduardo Ceballos (Parte 2)"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/5SgWPheDpr8?feature=oembed"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""
          />
        </div>

        <div className="video-padding">
          <iframe
            className="lazyload"
            title="Vivencias - Eduardo Ceballos (Parte 3)"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/HB_h0rDA5P0?feature=oembed"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""
          />
        </div>

        <div className="video-padding">
          <iframe
            className="lazyload"
            title="AADI PRESENTÓ EL FESTIVAL HACIENDO CAMINOS-TV DOS SALTA"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/vhi2Te_nIJQ?feature=oembed"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""
          />
        </div>

        <div className="video-padding">
          <iframe
            className="lazyload"
            title="Relato de Eduardo Ceballos"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/VucGG0baWlk?feature=oembed"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""
          />
        </div>

        <div className="video-padding">
          <iframe
            className="lazyload"
            title="La Gauchita"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/BSsABZZP-mw?feature=oembed"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""
          />
        </div>

        <div className="video-padding">
          <iframe
            className="lazyload"
            title="Grandeza Cósmica"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/mVv00SYounM?feature=oembed"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""
          />
        </div>

        <div className="video-padding">
          <iframe
            className="lazyload"
            title="LA SANGRE"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/RiRm9vbS9Pc?feature=oembed"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""
          />
        </div>

        <div className="video-padding">
          <iframe
            className="lazyload"
            title="Viaje Espacial"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/C_jYEBH0P_k?feature=oembed"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""
          />
        </div>

        <div className="video-padding">
          <iframe
            className="lazyload"
            title="Día del escritor salteño"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/Bl0OorOM7uM?feature=oembed"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""
          />
        </div>

        <div className="video-padding">
          <iframe
            className="lazyload"
            title="La Memoria"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/0HvW_k7zkGk?feature=oembed"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""
          />
        </div>

        <div className="video-padding">
          <iframe
            className="lazyload"
            title="TUNA ESPER EN SALTA NUESTRA - Iglesia San Fsco - Relato E. Ceballos"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/G9wuh4bGBoU?feature=oembed"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""
          />
        </div>

        <div className="video-padding">
          <iframe
            className="lazyload"
            title='"EL SILLÓN DEL PELUQUERO"'
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/VArHKkMNc0c?feature=oembed"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""
          />
        </div>
      </div>
    </Layout>
  )
}

export default VideosOtros
